// App.js
import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './App.css';

const ItemType = 'OBJECT_ITEM';

// Editable Item Component
const EditableItem = ({ item, onUpdate }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    onUpdate({
      ...item,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className="editable-item">
      <input
        type="text"
        name="name"
        value={item.name}
        onChange={handleChange}
        placeholder="Name"
      />
      <input
        type="number"
        name="value1"
        value={item.value1}
        onChange={handleChange}
        placeholder="Value 1"
      />
      <input
        type="number"
        name="value2"
        value={item.value2}
        onChange={handleChange}
        placeholder="Value 2"
      />
      <label>
        <input
          type="checkbox"
          name="isLegal"
          checked={item.isLegal}
          onChange={handleChange}
        />
        Is Legal
      </label>
    </div>
  );
};

// Draggable Item Component
const DraggableItem = ({ item }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType,
    item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className="draggable-item"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {item.name}
    </div>
  );
};

// Drop Zone Component
const DropZone = ({ onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemType,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className="drop-zone"
      style={{ backgroundColor: isOver ? '#f0f0f0' : '#fff' }}
    >
      {children}
    </div>
  );
};

function App() {
  const [items, setItems] = useState([
    { id: 1, name: 'FICO=300', value1: 650, value2: -.1, isLegal: true },
    { id: 13, name: 'FICO=700', value1: 650, value2: .1, isLegal: true },
    { id: 2, name: 'Vehicle Price < 15000', value1: 23000, value2: .04, isLegal: true },
    { id: 2, name: 'Vehicle Price > 100000', value1: 23000, value2: -.06, isLegal: true },
    { id: 3, name: 'Watches Reality TV', value1: 1, value2: -.05, isLegal: true },
    { id: 4, name: 'HasTrade', value1: 1000, value2: .01, isLegal: true },
    { id: 5, name: 'HasCat', value1: 650, value2: .01, isLegal: true },
    { id: 6, name: 'Liked The new Star Wars Trilogy', value1: -.1, value2: -.07, isLegal: true },
    { id: 7, name: 'Uses the HOV lane solo', value1: 1, value2: -.08, isLegal: true },
    { id: 8, name: 'Favorite flavor is Salt', value1: 1, value2: .03, isLegal: true },
    { id: 9, name: 'Flipped off a box of kittens', value1: 650, value2: .01, isLegal: true },
    { id: 10, name: 'Likes Ranch Dressing', value1: 23000, value2: .01, isLegal: true },
    { id: 11, name: 'Appeared on Reality TV', value1: 1, value2: -.3, isLegal: true },
    { id: 12, name: 'Is 2 kids in a trenchcoat', value1: 1000, value2: .4, isLegal: true }
  ]);
  const [droppedItems, setDroppedItems] = useState([]);

  const handleUpdateItem = (updatedItem) => {
    setItems(items.map((item) => (item.id === updatedItem.id ? updatedItem : item)));
  };

  const handleDrop = (item) => {
    if (!droppedItems.some((droppedItem) => droppedItem.id === item.id) && droppedItems.length < 6) {
      setDroppedItems([...droppedItems, item]);
      droppedItems.push(item);
    }
  };
  const handleClearDroppedItems = () => {
    for(let i = 0; i < droppedItems.length; i++)
    {
      droppedItems.pop();
    }
    setDroppedItems([]);
  };
  const totalScore = droppedItems.reduce((acc, item) => acc + parseFloat(item.value2), 0);
  const hasIllegalItems = droppedItems.some((item) => !item.isLegal);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        <div className="container">
          <div className="box">
            <h3>Credit Attributes</h3>
            <h6>Carvana uses over 2000 of these!</h6>
            {items.map((item) => (
              <DraggableItem key={item.id} item={item} />
            ))}
          </div>
          <div className="box">
            <h3>Pick 6 attributes!</h3>
            <DropZone onDrop={handleDrop}>
              {droppedItems.map((item, index) => (
                <div key={index} className="dropped-item">
                  {item.name}
                </div>
              ))}
            </DropZone>
            <button onClick={handleClearDroppedItems} className="clear-button">
              Clear Drop Zone
            </button>
          </div>
        </div>
        <div className="score-display" style={{ color: hasIllegalItems ? 'red' : 'black' }}>
          <h1>Chance of default on loan: {Math.max(30 - (totalScore.toFixed(2) * 100), 0)} %</h1>
        </div>
      </div>
    </DndProvider>
  );
}

export default App;